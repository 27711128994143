/* site.css 22/06/24 */

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --bodybg: #111;
    --bodycolour: #999;
    --accent: blueviolet;
}

body * {
    line-height: 1.2em;
    margin-bottom: .6em;
}

ul {
    list-style: disc;
    margin-left: 1em;
}

li {
    margin-bottom: 0;
}

@font-face {
    font-family: "Mona Sans";
    src:
        url(Mona-Sans.woff2) format("woff2 supports variations"),
        url(Mona-Sans.woff2) format("woff2-variations");
    font-weight: 200 900;
    font-stretch: 75% 125%;
}

body {
    background:var(--bodybg);
    color: var(--bodycolour);
    font-family: "Mona Sans";
    font-size: 3em;
    font-weight: 300;
}

h1 {
    color: var(--accent);
    font-size: 2em;
    font-weight: 300;
    margin: 10vh 0;
}

h2, h3 {
    color: var(--accent);
    font-size: 1.5em;
    font-weight: 300;
    margin-bottom: 2vh;
}

a, a:focus, a:hover, a:active {
    color: var(--accent);
    &:focus, &:hover, &:active {
        text-decoration-thickness: from-font;
    }
}

.cursor {
    font-weight: 300;
    color: var(--accent);
    animation: 1s blink step-end infinite;
}

@keyframes blink {
    from, to {
        color: rgba(0,0,0,0);
    }
    50% {
        color: var(--accent);
    }
}

footer {
    padding-top: 5vh;
}

/* small screens */
@media screen and (max-width: 768px) {
    body {
        font-size: 7vw;
        font-weight: 400;
    }
    h1 {
        font-size: 10vw;
    }
    h2, h3 {
        font-size: 8vw;
    }
}